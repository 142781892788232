import { navigate } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { allTrialsIds } from '../constants/config';
import NProgress from 'nprogress';

class OldStudy extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props && this.props.location) {
      const { search } = this.props.location;
      const params = new URLSearchParams(search);
      let studyId = params.get('id');
      const loadPage = () => {
        params.delete('id');
        const otherParams = Object.entries(Object.fromEntries(params))
          .map(
            ([key, value]) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
          )
          .join('&');
        studyId
          ? navigate(`/study/${studyId}${otherParams ? `?${otherParams}` : ''}`)
          : navigate(`/404`);
      };
      if (studyId.includes('NCT')) {
        NProgress.configure({ showSpinner: false }).start();
        axios
          .get(allTrialsIds())
          .then(trials => {
            const itemFound = trials.data.Data.find(
              ids => ids.NCTID === studyId
            );
            console;
            if (itemFound) {
              studyId = itemFound.UniqueIdentifier;
            }
            loadPage();
            NProgress.done();
          })
          .catch(() => {
            NProgress.done();
            loadPage();
          });
      } else {
        loadPage();
      }
    }
  }

  render() {
    return null;
  }
}

OldStudy.propTypes = {
  location: PropTypes.object.isRequired,
};

export default OldStudy;
